import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { WppTypography, WppCard } from '@platform-ui-kit/components-library-react'
import { Link as CustomLink } from 'react-router-dom'

import { Flex } from 'ui-base/flex/Flex'
import { CustomWppSpinner } from 'ui-base/spinner/Spinner'
import { Truncate } from 'ui-base/truncate/Truncate'

const typographyStyle = css`
  color: var(--wpp-grey-color-800);
`

export const Link = styled(CustomLink)`
  text-decoration: none;
`

export const ApplicationCard = styled(WppCard)`
  &::part(card) {
    display: flex;
    flex-direction: column;
    height: 140px;
    overflow: visible;
  }

  &::part(header-wrapper) {
    height: 50px;
    margin-bottom: 8px;
  }
`

export const Header = styled(Flex)`
  width: 100%;
`

export const HeaderNamePart = styled(Flex)`
  flex: 1;
  max-width: calc(100% - 56px);
`

export const ApplicationNameWrapper = styled(Flex)`
  overflow: hidden;
`

export const ApplicationName = styled(Truncate)`
  color: var(--wpp-grey-color-1000);
  overflow: hidden;

  &::part(typography) {
    display: block;
  }
`

export const ApplicationCardContent = styled('div')`
  flex-grow: 1;
`

export const ApplicationDescription = styled(Truncate)`
  ${typographyStyle};
  height: 22px;

  &::part(typography) {
    display: block;
  }
`

export const ApplicationCardFooter = styled('footer')`
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 15px;
`

export const CertificationLevelWrapper = styled('div')`
  .wpp-internal-tooltip {
    ::part(tooltip-content) {
      white-space: nowrap;
    }
  }
`

export const ApplicationReviews = styled(WppTypography)`
  ${typographyStyle}
`

export const Spinner = styled(CustomWppSpinner)`
  padding: 0;
`
